<template>
  <div class="courseUser"> 
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                <em>当前位置：</em>
                <a href="javascript:;">数据统计</a>
                <i>></i>
                <a href="javascript:;" @click="$router.back()">问卷统计</a>
                <i>></i>
                <a href="javascript:;" @click="init" class="cur-a">每日问卷汇总</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl " style="display:flex;flex-direction: column;align-items: flex-start">
                    <div class="searchbox" style="margin-bottom:15px">
                        
                        <div title="课件名称" class="searchboxItem ci-full">
                            <span class="itemLabel" style="min-width:6rem">课件名称:</span>
                            <el-input
                                    v-model="selectData.catalogName"
                                    type="text"
                                    size="small"
                                    clearable
                                    placeholder="请输入课件名称"
                            />
                        </div>
                         <div class="df">
                            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
                        </div>
                    </div>
                    
                </div>
                 <div class="framePage-scroll">
                    <div class="ovy-a">

                      <el-table
                        ref="multipleTable"
                        :data="tableData"
                        :height="tableHeight"
                        size="small"
                        tooltip-effect="dark"
                        style="width: 100%"
                        :header-cell-style="tableHeader"
                        stripe
                      >
                        <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
                          <el-table-column label="班级名称" align="left" show-overflow-tooltip prop="projectName" min-width="200"/>
                            <el-table-column label="课程名称" align="left" show-overflow-tooltip prop="projectCourseName" />
                            <el-table-column label="课件名称" align="right" show-overflow-tooltip prop="catalogName" />
                        <el-table-column label="操作" width="320px" align="center" >
                          <template slot-scope="scope">
                            <el-button
                              type="text"
                              size="mini"
                              style="padding:0 5px"
                              @click="DailysummaryDetail(scope.row)"
                            >预览</el-button>
                           
                          </template>
                        </el-table-column>
                        <Empty slot="empty" />
                      </el-table>
                    </div>
                  </div>
                  <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "set/courseUser",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
      selectData:{
        catalogName: "",
      },
    };
  },
  computed: {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableHeight();
      this.getData();
    },
    getData(pageNum) {
      const params = {
        ...this.selectData,
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectCourseId:this.$route.query.projectCourseId,
        projectId:this.$route.query.projectId
      };
      this.doFetch({
        url: "/survey/sjzcx/everyday/page",
        params,
        pageNum
      });
    },
    DailysummaryDetail(row){
this.$router.push({
        path: "/web/set/questionnaireDailysummaryDetail",
        query: {
           editInfo: JSON.stringify({
                   row
                  }),
        }
      })
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  mounted: function() {}
};
</script>

<style lang="less" scope>
.courseUser{
  height: 100%;
}
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.el-table th.is-left {
  >.cell {
    text-align: left;
  }
}
.el-table th.is-right {
  >.cell {
    text-align: right;
  }
}
</style>
